import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import "./dental-implants-today.scss"

import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Form from "../components/Form"

const DentalImplantsToday = ({ location, data }) => {
  const language = "en"
  const post = data.dit.nodes[0]
  const facewall = data.facewall.nodes
  const locations = data.locations.nodes

  const formRef = useRef(null)

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const strippedPhone = post.phoneNumber.replace(/[^+\d]+/g, "")

  const phoneButton = (
    <a
      className="standard-button contained"
      href={`tel:+1${strippedPhone}`}
      title="Give us a call"
    >
      {post.phoneNumber}
    </a>
  )

  const scrollToForm = () => {
    window.scroll({
      top: formRef.current.offsetTop,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className="dit">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        schemaPage={post.schemaPage ? post.schemaPage : null}
      />

      {post.trackingScript && (
        <Helmet>
          <script type="text/javascript">{`${post.trackingScript}`}</script>
        </Helmet>
      )}

      <nav className="dit__nav" id="mainNav">
        <Link to="/" title="Return to homepage">
          <NuvoImage publicId="MOFS/DEV/logo" cloudName="nuvolum" />
        </Link>
      </nav>

      <main>
        <div className="dit__header dit__container dit__section">
          <h1>{post.heading}</h1>
          <MarkdownViewer markdown={post.blurb.text} />
          <div className="dit__button-group">
            {phoneButton}{" "}
            <a className="standard-button contained" onClick={scrollToForm}>
              {post.blurb.button.buttonText}
            </a>
          </div>

          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${post.youtube.code}`}
            overVideo={true}
            language={language}
            buttonClass="contained"
          >
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={post.youtube.image}
              width="auto"
              className="theater-image"
            ></NuvoImage>
          </TheaterVideo>
        </div>

        <div className="dit__section dit__container">
          <div className="dit__form-section">
            <MarkdownViewer markdown={post.form.text} />
            <div id="dit__form" ref={formRef}>
              <Form src={post.form.url} />
            </div>
          </div>
        </div>

        <div className="dit__section">
          <div className="dit__appointment">
            <div className="dit__container">
              <h3>{post.appointment.blurb}</h3>
              {phoneButton}
            </div>
          </div>
        </div>

        <div className="dit__section">
          <div className="dit__testimonial">
            <div className="dit__container">
              <MarkdownViewer markdown={post.testimonials.text} />
            </div>

            <div className="dit__container large">
              <div className="dit__testimonial-facewall">
                {facewall.map((patient, i) => (
                  <div key={patient.heading} className="dit__facewall-card">
                    <NuvoImage
                      cloudName="nuvolum"
                      publicId={patient.thumbnailPublicId}
                      width="auto"
                    />
                    <h5>{`Meet ${patient.reviewerName}`}</h5>
                    <p>{`${month[parseInt(patient.monthYear.month) - 1]} ${
                      patient.monthYear.year
                    }`}</p>
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${patient.youtube}`}
                      language={"en"}
                      buttonClass="contained"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="dit__footer">
        <div className="dit__container">
          <div className="dit__locations">
            {locations.map(({ locationData }) => (
              <div
                key={locationData.locationName}
                className="dit__location-card"
              >
                <h6>{`${locationData.locationName} Office`}</h6>
                <a
                  href={locationData.googlePlaceLink}
                  target="_blank"
                  title="Get Directions"
                >
                  <p>{`${locationData.streetNumber} ${locationData.streetName}`}</p>
                  <p>{`${locationData.cityName}, ${locationData.stateAbbr} ${locationData.cityZip}`}</p>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="dit__privacy-footer" style={{ marginTop: 50 }}>
          <Link to="/privacy-policy/" title="Read the privacy policy">
            Privacy Policy
          </Link>
          <Link to="/terms-of-use/" title="Read the terms of use">
            Terms of Use
          </Link>
          <Link
            to="/accessibility-statement/"
            title="Read the ADA accessibility statement"
          >
            ADA Accessibility Statement
          </Link>
          <Link to="/hipaa-policy/" title="Read the HIPAA compliance">
            HIPAA Compliance
          </Link>
        </div>
      </footer>
    </div>
  )
}

export const pageQuery = graphql`
  query dentalImplantsTodayQuery($title: String!) {
    dit: allDentalImplantsTodayJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        trackingScript
        phoneNumber
        heading
        blurb {
          button {
            buttonText
          }
          text
        }
        phoneNumber
        testimonials {
          text
        }
        youtube {
          code
          image
        }
        form {
          text
          url
        }
        appointment {
          blurb
        }
        testimonials {
          text
        }
      }
    }

    facewall: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { eq: "Dental Implants" } }
          }
        }
      }
      limit: 4
    ) {
      nodes {
        title
        thumbnailPublicId
        reviewerName
        youtube
        monthYear {
          month
          year
        }
      }
    }

    locations: allLocationsJson(
      sort: { order: ASC, fields: locationData___order }
    ) {
      nodes {
        locationData {
          cityName
          cityZip
          locationHours {
            endTime
            startTime
            weekDay
          }
          googlePlaceLink
          order
          stateAbbr
          stateName
          streetName
          streetNumber
          locationName
        }
      }
    }
  }
`

export default DentalImplantsToday
